<template>
  <div>
    <div @click="$router.go(-1)"
      style="display: flex;align-items: center;margin-left: 20px;font-size: 20px;cursor: pointer;margin-top: 20px;">
      <a-icon style="font-size: 30px;margin-right: 10px;" type="left-circle" />
      <span>返回</span>
    </div>
    <a-card style="background-color: #fff;">
      <a-row>
        <a-button @click="addBtn" type="primary">新增地址</a-button>
      </a-row>
      <!-- 创建 -->
      <a-modal title="新增地址" :visible="visible" :confirm-loading="confirmLoading" :maskClosable="false" @ok="handleOk"
        :footer="null" @cancel="visible = false" :width="800">
        <a-form-model :label-col="labelCol" ref="ruleForm" :model="form" :rules="rules" :wrapper-col="wrapperCol">
          <a-form-model-item ref="consignee" prop="consignee" label="联系人" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-input v-model="form.consignee" placeholder="请输入联系人" />
          </a-form-model-item>

          <a-form-model-item ref="mobile" prop="mobile" label="手机号" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-input v-model="form.mobile" placeholder="联系人手机号" />
          </a-form-model-item>
          <a-form-model-item ref="provinceName" prop="provinceName" label="省" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select @change="provChange($event)" placeholder="请选择省" v-model="form.provinceName">
              <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in prov" :key="index">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item ref="cityName" prop="cityName" label="市" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select @change="cityChange($event)" placeholder="请选择市" v-model="form.cityName">
              <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in city" :key="index">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="countyName" prop="countyName" label="区" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select @change="areaChange($event)" placeholder="请选择区" v-model="form.countyName">
              <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in area" :key="index">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="detailedAddress" prop="detailedAddress" label="详细地址" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-input v-model="form.detailedAddress" placeholder="小区门牌号" />
          </a-form-model-item>



        </a-form-model>

        <div style="display: flex;justify-content: center;">
          <a-button type="primary" @click="handleOk" style="margin-right: 10px;">保存</a-button>
          <!-- <a-button type="primary" @click="ToDetail">立即选品</a-button> -->
        </div>
      </a-modal>

      <!-- 修改 -->
      <a-modal title="编辑地址" :visible="EditVisible" :confirm-loading="confirmLoading" :maskClosable="false"
        @ok="handleEdit" :footer="null" @cancel="EditVisible = false" :width="800">
        <a-form-model :label-col="labelCol" ref="editRuleForm" :model="editForm" :rules="rules" :wrapper-col="wrapperCol">
          <a-form-model-item ref="consignee" prop="consignee" label="联系人" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-input v-model="editForm.consignee" placeholder="请输入联系人" />
          </a-form-model-item>

          <a-form-model-item ref="mobile" prop="mobile" label="手机号" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-input v-model="editForm.mobile" placeholder="联系人手机号" />
          </a-form-model-item>

          <a-form-model-item ref="provinceName" prop="provinceName" label="省" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select @change="provChange($event, 1)" v-model="editForm.provinceName" placeholder="请选择省">
              <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in prov" :key="index">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item ref="cityName" prop="cityName" label="市" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select @change="cityChange($event, 1)" v-model="editForm.cityName" placeholder="请选择市">
              <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in city" :key="index">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="countyName" prop="countyName" label="区" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select @change="areaChange($event, 1)" v-model="editForm.countyName" placeholder="请选择区">
              <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in area" :key="index">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="detailedAddress" prop="detailedAddress" label="详细地址" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-input v-model="editForm.detailedAddress" placeholder="小区门牌号" />
          </a-form-model-item>



        </a-form-model>

        <div style="display: flex;justify-content: center;">
          <a-button type="primary" @click="handleEdit" style="margin-right: 10px;">保存</a-button>
          <!-- <a-button type="primary" @click="ToDetail">立即选品</a-button> -->
        </div>
      </a-modal>




      <div style="margin-top: 20px;">
        <a-table :columns="columns" :dataSource="dataSource" :loading="tabLoading" bordered :pagination="false">
          <template slot="createTime" slot-scope="text">{{ formatDate(text) }}</template>
          <template slot="expectedProcurementTime" slot-scope="text">
            <span v-if="text">{{ formatDate(text) }}</span>
            <span v-else>-</span>
          </template>
          <template slot="staffName" slot-scope="text">
            <p v-if="text">{{ text }}</p>
            <p v-else>-</p>
          </template>

          <template slot="name" slot-scope="text">
            <a-popover>
              <template slot="content">
                {{ text }}
              </template>
              {{ text }}
            </a-popover>
          </template>
          <template slot="customerName" slot-scope="text">
            <a-popover>
              <template slot="content">
                {{ text }}
              </template>
              {{ text }}
            </a-popover>
          </template>


          <template slot="action" slot-scope="record">
            <div>
              <div>
                <a @click="editChange(record)" style="margin-right: 10px;"
                  v-if="hasPermission('UPDATE_CONTACTADDRESS')">编辑</a>
                <a-popconfirm style="cursor: pointer;" title="您确定要删除吗？" ok-text="是" cancel-text="否"
                  @confirm="delConfirm(record)" v-if="hasPermission('DEL_CONTACTADDRESS')">删除</a-popconfirm>
              </div>
            </div>
          </template>
        </a-table>

      </div>
    </a-card>
  </div>
</template>
  
<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
const columns = [
  {
    title: '联系人',
    dataIndex: 'consignee',
    scopedSlots: { customRender: 'consignee' },
    align: 'center',
    width: 30,
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' },
    ellipsis: true,
    align: 'center',
    width: 30,
  },
  {
    title: '地址',
    dataIndex: 'addressFull',
    scopedSlots: { customRender: 'addressFull' },
    ellipsis: true,
    align: 'center',
    width: 30,
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 30,
  }
]

export default {
  name: 'QueryList',
  data() {
    return {
      iptmd: 4,
      iptsm: 22,
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      tabLoading: false,
      selectedRows: [],
      total: 0,
      currentPage: 1,
      prov: [],
      city: [],
      area: [],
      prov1: [],
      prov2: [],
      prov3: [],
      // 新建方案
      visible: false,
      confirmLoading: false,
      // 修改方案
      EditVisible: false,
      editForm: {},
      // 新建方案弹窗
      labelCol: { span: 8 },
      wrapperCol: { span: 24 },
      queryData: {},
      // 收货地址列表
      sfaContactAddressList: [{
        "consignee": "",
        "mobile": "",
        "provinceCode": "",
        "provinceName": "",
        "cityCode": "",
        "cityName": "",
        "countyCode": "",
        "countyName": "",
        "detailedAddress": ""
      }],
      rules: {
        provinceName: [{ required: true, message: '请选择省', trigger: 'change' }],
        cityName: [{ required: true, message: '请选择市', trigger: 'change', }],
        countyName: [{ required: true, message: '请选择区', trigger: 'change' }],
        detailedAddress: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        consignee: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
      },
      form: {
        "provinceCode": "110000",
        "provinceName": "北京市",
        "cityCode": "110100",
        "cityName": "北京市",
      }
    }
  },

  mounted() {
    this.getCity();
    this.getData()
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    addBtn() {
      this.visible = true;
      this.getCity();
    },
    getCity() {
        request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/0', 'get').then(res => {
          if (res.data.code == 200) {
            this.prov = res.data.data;
            this.gsAddress = res.data.data;
          } else {
            this.$message.error(res.data.message)
          }
        })
        // 市
        request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/110000', 'get').then(res => {
          if (res.data.code == 200) {
            this.city = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        })
        // 区
        request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/110100', 'get').then(res => {
          if (res.data.code == 200) {
            this.area = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        })
      },
    provChange(id, type) {
      if (type) {
        const selProv = this.prov.find(item => item.id === id);
        this.editForm.provinceCode = selProv.id;
        this.editForm.provinceName = selProv.name;
        delete this.editForm.cityName;
        delete this.editForm.cityCode;
        delete this.editForm.countyName;  
        delete this.editForm.countyCode;
      } else {
        const selProv = this.prov.find(item => item.id === id);
        this.form.provinceCode = selProv.id;
        this.form.provinceName = selProv.name;
        delete this.form.cityName;
        delete this.form.cityCode;  
        delete this.form.countyName;  
        delete this.form.countyCode;  
        
      }
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.city = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    cityChange(id, type) {
      if (type) {
        const selProv = this.city.find(item => item.id === id);
        this.editForm.cityCode = selProv.id;
        this.editForm.cityName = selProv.name;
      } else {
        const selProv = this.city.find(item => item.id === id);
        this.form.cityCode = selProv.id;
        this.form.cityName = selProv.name;
      }
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.area = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    areaChange(id, type) {
      if (type) {
        const selProv = this.area.find(item => item.id === id);
        this.editForm.countyCode = selProv.id;
        this.editForm.countyName = selProv.name;
      } else {
        const selProv = this.area.find(item => item.id === id);
        this.form.countyCode = selProv.id;
        this.form.countyName = selProv.name;
      }

    },

    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    handleEdit(e) {
      e.preventDefault()
      this.$refs.editRuleForm.validate(valid => {
        if (valid) {
          if(!this.editForm.cityName || !this.editForm.countyName || !this.editForm.provinceName) {
            this.$message.warn('请不全信息！')
            return false;
          }
          // this.form.customerId = this.$route.query.id
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/contactaddress/update', 'post', this.editForm).then(res => {
            if (res.data.code == 200) {
              this.$message.success('修改成功！')
              this.EditVisible = false;
              this.sfaContactAddressList = []

              this.getData()
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          return false;
        }
      });
    },
    handleOk(e) {
      e.preventDefault()
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.sfaContactAddressList[0] = this.form
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/contactaddress/save/' + this.$route.query.id, 'post', this.sfaContactAddressList).then(res => {
            if (res.data.code == 200) {
              this.$message.success('添加成功！')
              this.visible = false;
              this.form = {}
              this.sfaContactAddressList = []
              this.prov1 = []
              this.prov2 = []
              this.prov3 = []
              this.getData()
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          return false;
        }
      });
    },
    editChange(e) {
      let obj = JSON.parse(JSON.stringify(e))
      this.editForm = obj;
      this.EditVisible = true;
      this.getCity();
    },
    // 获取列表 
    getData() {
      this.tabLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/contactaddress/list/' + this.$route.query.id, 'get').then(res => {
        let data = res.data.data;
        this.tabLoading = false;
        if (res.data.code == 200) {
          this.dataSource = data;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 是否确认删除
    delConfirm(e) {
      this.$message.loading();
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/contactaddress/' + e.id, 'delete').then(res => {
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.EditVisible = false;
          this.getData()
        }
      })
    },

  }
}
</script>
  
<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}



@media (max-width: 1800px) {
  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  ::v-deep .ant-col-md-4 {
    // width: 16% !important;
    // margin-right: 20px;
  }
}


@media (max-width: 1600px) {
  ::v-deep .ant-col-md-4 {
    width: 18% !important;
  }
}
</style>
  
  